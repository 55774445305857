import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { TableTextField } from "./TableTextField";
import { TableSelect } from "./TableSelect";
export function Filter({ column }) {
    var _a, _b;
    if (!column.getCanFilter())
        return _jsx(_Fragment, {});
    const columnFilterValue = column.getFilterValue();
    return column.columnDef["checkbox"] ? (_jsx(TableSelect, { onChange: (value) => column.setFilterValue(value), value: columnFilterValue ? columnFilterValue.toString() : "" })) : column.columnDef["isNumber"] ? (_jsxs(Grid, Object.assign({ container: true, direction: "row", spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TableTextField, { type: "number", value: (_a = columnFilterValue === null || columnFilterValue === void 0 ? void 0 : columnFilterValue[0]) !== null && _a !== void 0 ? _a : "", onChange: (value) => column.setFilterValue((old) => [value, old === null || old === void 0 ? void 0 : old[1]]), placeholder: `Min` }) })), _jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(TableTextField, { type: "number", value: (_b = columnFilterValue === null || columnFilterValue === void 0 ? void 0 : columnFilterValue[1]) !== null && _b !== void 0 ? _b : "", onChange: (value) => column.setFilterValue((old) => [old === null || old === void 0 ? void 0 : old[0], value]), placeholder: `Max` }) }))] }))) : (_jsx(TableTextField, { type: "text", value: (columnFilterValue !== null && columnFilterValue !== void 0 ? columnFilterValue : ""), onChange: (value) => column.setFilterValue(value), placeholder: `Search...`, sx: { "& .MuiInputBase-root": { p: 0 } } }));
}
