import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Checkbox } from "@mui/material";
import { TableCheckboxIcon } from "../../utils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
export function TableCheckbox({ checkbox, value, id, currentIndex, originalIndex, table, }) {
    let icon = undefined;
    let checkedIcon = undefined;
    switch (checkbox) {
        case TableCheckboxIcon.heart:
            icon = _jsx(FavoriteBorderIcon, {});
            checkedIcon = _jsx(FavoriteIcon, {});
            break;
        case TableCheckboxIcon.bookmark:
            icon = _jsx(BookmarkBorderIcon, {});
            checkedIcon = _jsx(BookmarkIcon, {});
            break;
        case TableCheckboxIcon.star:
            icon = _jsx(StarBorderIcon, {});
            checkedIcon = _jsx(StarIcon, {});
            break;
        case TableCheckboxIcon.square:
            break;
        default:
    }
    const _value = value ? value : "";
    const [checked, setChecked] = useState(_value.toString().toLowerCase() === "true");
    useEffect(() => {
        setChecked(_value.toString().toLowerCase() === "true");
    }, [_value]);
    return (_jsx(Checkbox, { color: "primary", checked: checked, size: "small", icon: icon, checkedIcon: checkedIcon, onChange: (e) => {
            var _a;
            setChecked(e.target.checked);
            const newValue = e.target.checked ? "true" : "false";
            //@ts-ignore
            (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.updateData(currentIndex, originalIndex, id, newValue);
        }, onClick: (e) => e.stopPropagation(), inputProps: { "aria-label": "controlled" } }));
}
