import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useRef, useState } from "react";
import { TableTextField } from "./TableButtons/TableTextField";
import { showInExponentialNotation } from "./utils";
import { TableCheckbox } from "./TableButtons/TableCheckox";
export const getDefaultColumn = (tableContainerRef) => {
    return {
        cell: ({ getValue, row: { index, original }, column, table }) => {
            const originalIdx = original["_pmv_originalId"];
            const initialValue = getValue();
            const isComment = column.columnDef["isComment"];
            const checkbox = column.columnDef["checkbox"];
            return checkbox ? (_jsx(TableCheckbox, { checkbox: checkbox, value: initialValue, id: column.id, currentIndex: index, originalIndex: originalIdx, table: table })) : isComment && originalIdx !== undefined ? (_jsx(DefaultEditableCell, { initialValue: getValue(), id: column.id, currentIndex: index, originalIndex: originalIdx, table: table, tableContainerRef: tableContainerRef })) : (showInExponentialNotation(initialValue));
        },
    };
};
const DefaultEditableCell = ({ initialValue, id, currentIndex, originalIndex, table, tableContainerRef, }) => {
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef(null); // Ref for the input element
    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        var _a;
        //@ts-ignore
        (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.updateData(currentIndex, originalIndex, id, value);
    };
    const onScroll = () => {
        if (inputRef.current && document.activeElement === inputRef.current) {
            const refCurrent = inputRef.current;
            refCurrent.blur(); // Unfocus the input
        }
    };
    useEffect(() => {
        if (tableContainerRef) {
            tableContainerRef.addEventListener("scroll", onScroll);
        }
        return () => {
            if (tableContainerRef) {
                tableContainerRef.removeEventListener("scroll", onScroll);
            }
        };
    }, []);
    return (_jsx(TableTextField, { ref: inputRef, type: "text", value: initialValue, placeholder: `...`, onChange: (value) => setValue(value), onBlur: onBlur, multiline: true, maxRows: 6 }));
};
