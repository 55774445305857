export function applySorting(sorting, dbData) {
    if (sorting.length === 0)
        return dbData;
    const dbDatasort = [...dbData];
    const sort = sorting[0];
    const { id, desc } = sort;
    dbDatasort.sort((a, b) => {
        if (desc) {
            return a[id] < b[id] ? 1 : -1;
        }
        return a[id] > b[id] ? 1 : -1;
    });
    return dbDatasort;
}
function convertToNum(val) {
    const numericValue = parseFloat(val);
    return isNaN(numericValue) ? undefined : numericValue;
}
export function applyColFilter(columnFilters, dbData) {
    try {
        const filters = [];
        columnFilters.forEach((colFilter) => {
            const value = colFilter.value;
            if (typeof value !== "string" && value.length === 2) {
                //Numeric filter
                let [min, max] = value;
                const minNum = convertToNum(min);
                const maxNum = convertToNum(max);
                if (minNum !== undefined || maxNum !== undefined) {
                    filters.push({
                        id: colFilter.id,
                        type: "number",
                        min: minNum,
                        max: maxNum,
                    });
                }
            }
            else {
                //String filter
                filters.push({
                    id: colFilter.id,
                    type: "string",
                    value: value ? value.toLowerCase() : value,
                });
            }
        });
        if (filters.length > 0) {
            // console.log(filters);
            dbData = dbData.filter((row) => {
                let keep = true;
                filters.forEach((filter) => {
                    if (filter.type === "number") {
                        const { id, min, max } = filter;
                        const rownum = row[id];
                        if ((min || min == 0) && rownum < min)
                            keep = false;
                        if ((max || max == 0) && rownum > max)
                            keep = false;
                    }
                    else {
                        const { id, value } = filter;
                        if (!row[id] || !`${row[id]}`.toLowerCase().includes(value))
                            keep = false;
                    }
                });
                return keep;
            });
        }
    }
    catch (e) {
        console.log(e);
    }
    return dbData;
}
export const fetchData = (start, size, data) => {
    let dbData = [...data];
    return {
        data: dbData.slice(start, start + size),
        meta: {
            totalRowCount: dbData.length,
        },
    };
};
