import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
import { FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { useEffect, useState } from "react";
//
export function TableSelect({ value: initialValue, onChange, }) {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    useEffect(() => {
        onChange(value);
    }, [value]);
    return (_jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { id: "demo-simple-select-label" }), _jsxs(Select, Object.assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", label: "", size: "small", 
                // value={age}
                // onChange={handleChange}
                value: value, onChange: (e) => {
                    setValue(e.target.value);
                }, sx: {
                    "& .MuiSelect-select": { fontSize: "0.8rem", py: "0.2rem" },
                }, inputProps: {
                    style: {
                        fontSize: "0.875rem",
                    },
                }, MenuProps: {
                    PaperProps: {
                        sx: {
                            "& .MuiMenuItem-root": {
                                fontSize: "0.875rem",
                            },
                        },
                    },
                }, renderValue: (selected) => {
                    return selected === "" ? "All" : selected;
                }, displayEmpty: true }, { children: [_jsx(MenuItem, Object.assign({ dense: true, sx: { fontSize: "0.875rem" }, value: "" }, { children: "All" })), _jsx(MenuItem, Object.assign({ dense: true, sx: { fontSize: "0.875rem" }, value: "True" }, { children: "True" })), _jsx(MenuItem, Object.assign({ dense: true, sx: { fontSize: "0.875rem" }, value: "False" }, { children: "False" }))] }))] })));
}
